"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Maybe_1 = require("./Maybe");
const _left = 'Left';
const _right = 'Right';
class Either {
    constructor(value, tag) {
        this.value = value;
        this.tag = tag;
        this.of = Either.of;
        this['fantasy-land/alt'] = this.alt;
        this['fantasy-land/of'] = this.of;
        this['fantasy-land/ap'] = this.ap;
        this['fantasy-land/chain'] = this.chain;
        this['fantasy-land/reduce'] = this.reduce;
        this['fantasy-land/map'] = this.map;
        this['fantasy-land/extend'] = this.extend;
        this['fantasy-land/equals'] = this.equals;
        this['fantasy-land/bimap'] = this.bimap;
    }
    asLeft() {
        return this;
    }
    asRight() {
        return this;
    }
    isLeft_() {
        return this.isLeft();
    }
    isRight_() {
        return this.isRight();
    }
    static of(value) {
        return exports.Right(value);
    }
    static lefts(list) {
        return list.filter(x => x.isLeft()).map(x => x.asLeft().value);
    }
    static rights(list) {
        return list.filter(x => x.isRight()).map(x => x.asRight().value);
    }
    static encase(throwsF) {
        try {
            return exports.Right(throwsF());
        }
        catch (e) {
            return exports.Left(e);
        }
    }
    isLeft() {
        return this.tag === _left;
    }
    isRight() {
        return this.tag === _right;
    }
    toJSON() {
        return this.value;
    }
    inspect() {
        return `${this.tag}(${JSON.stringify(this.value)})`;
    }
    toString() {
        return this.inspect();
    }
    bimap(f, g) {
        return this.isLeft_() ? exports.Left(f(this.asLeft().value)) : exports.Right(g(this.asRight().value));
    }
    map(f) {
        return this.bimap(x => x, f);
    }
    mapLeft(f) {
        return this.bimap(f, x => x);
    }
    ap(other) {
        return other.isLeft_() ? other.asLeft() : this.map(other.asRight().value);
    }
    equals(other) {
        if (this.isLeft_() && other.isLeft_()) {
            return this.value === other.value;
        }
        if (this.isRight_() && other.isRight_()) {
            return this.value === other.value;
        }
        return false;
    }
    chain(f) {
        return this.isLeft_() ? this.asLeft() : f(this.asRight().value);
    }
    join() {
        return this.chain(x => x);
    }
    alt(other) {
        return this.isRight_() ? this : other;
    }
    reduce(reducer, initialValue) {
        return this.isLeft_() ? initialValue : reducer(initialValue, this.asRight().value);
    }
    extend(f) {
        return this.isLeft_() ? this.asLeft() : exports.Right(f(this));
    }
    unsafeCoerce() {
        return this.isLeft_() ? (() => { throw new Error('Either got coerced to a Left'); })() : this.asRight().value;
    }
    caseOf(patterns) {
        if ('_' in patterns) {
            return patterns._();
        }
        else {
            return this.isLeft_() ? patterns.Left(this.asLeft().value) : patterns.Right(this.asRight().value);
        }
    }
    leftOrDefault(defaultValue) {
        return this.isLeft_() ? this.asLeft().value : defaultValue;
    }
    orDefault(defaultValue) {
        return this.isRight_() ? this.asRight().value : defaultValue;
    }
    orDefaultLazy(getDefaultValue) {
        return this.isRight_() ? this.asRight().value : getDefaultValue();
    }
    leftOrDefaultLazy(getDefaultValue) {
        return this.isLeft_() ? this.asLeft().value : getDefaultValue();
    }
    ifLeft(effect) {
        return this.isLeft_() ? (effect(this.asLeft().value), this) : this;
    }
    ifRight(effect) {
        return this.isLeft_() ? this : (effect(this.asRight().value), this);
    }
    toMaybe() {
        return this.isLeft_() ? Maybe_1.Nothing : Maybe_1.Just(this.asRight().value);
    }
    leftToMaybe() {
        return this.isLeft_() ? Maybe_1.Just(this.asLeft().value) : Maybe_1.Nothing;
    }
    either(ifLeft, ifRight) {
        return this.isLeft_() ? ifLeft(this.asLeft().value) : ifRight(this.asRight().value);
    }
    extract() {
        return this.value;
    }
}
exports.Either = Either;
exports.Left = (value) => new Either(value, _left);
exports.Right = (value) => new Either(value, _right);
