export function addClass<T extends HTMLElement>(el: T, className: string) {
    el.classList.add(className);
}

export function hasClass<T extends HTMLElement>(el: T, className: string) {
    return el.classList.contains(className);
}

export function removeClass<T extends HTMLElement>(el: T, className: string) {
    el.classList.remove(className);
}