"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Either_1 = require("./Either");
class Maybe {
    constructor(value) {
        this.value = value;
        this.of = Maybe.of;
        this.zero = Maybe.zero;
        this.empty = Maybe.empty;
        this['fantasy-land/alt'] = this.alt;
        this['fantasy-land/of'] = this.of;
        this['fantasy-land/ap'] = this.ap;
        this['fantasy-land/chain'] = this.chain;
        this['fantasy-land/reduce'] = this.reduce;
        this['fantasy-land/map'] = this.map;
        this['fantasy-land/zero'] = this.zero;
        this['fantasy-land/extend'] = this.extend;
        this['fantasy-land/empty'] = this.empty;
        this['fantasy-land/equals'] = this.equals;
    }
    static of(value) {
        return exports.Just(value);
    }
    static fromNullable(value) {
        return value == null ? exports.Nothing : exports.Just(value);
    }
    static fromFalsy(value) {
        return value ? exports.Just(value) : exports.Nothing;
    }
    static fromPredicate(pred, value) {
        switch (arguments.length) {
            case 1:
                return (value) => Maybe.fromPredicate(pred, value);
            default:
                return pred(value) ? exports.Just(value) : exports.Nothing;
        }
    }
    static empty() {
        return exports.Nothing;
    }
    static zero() {
        return exports.Nothing;
    }
    static catMaybes(list) {
        return list.filter(x => x.isJust()).map(x => x.value);
    }
    static mapMaybe(f, list) {
        switch (arguments.length) {
            case 1:
                return (list) => Maybe.mapMaybe(f, list);
            default:
                return Maybe.catMaybes(list.map(f));
        }
    }
    static encase(thunk) {
        try {
            return exports.Just(thunk());
        }
        catch (_a) {
            return exports.Nothing;
        }
    }
    isJust() {
        return this.value !== null;
    }
    isNothing() {
        return this.value == null;
    }
    inspect() {
        return this.value === null ? 'Nothing' : `Just(${this.value})`;
    }
    toString() {
        return this.inspect();
    }
    toJSON() {
        return this.value;
    }
    equals(other) {
        return this.value === other.value;
    }
    map(f) {
        return this.isNothing() ? exports.Nothing : exports.Just(f(this.value));
    }
    ap(maybeF) {
        return maybeF.isNothing() ? exports.Nothing : this.map(maybeF.value);
    }
    alt(other) {
        return this.isNothing() ? other : this;
    }
    chain(f) {
        return this.isNothing() ? exports.Nothing : f(this.value);
    }
    join() {
        return this.chain(x => x);
    }
    reduce(reducer, initialValue) {
        return this.isNothing() ? initialValue : reducer(initialValue, this.value);
    }
    extend(f) {
        return this.isNothing() ? exports.Nothing : exports.Just(f(this));
    }
    unsafeCoerce() {
        return this.isNothing() ? (() => { throw new Error('Maybe got coerced to a null'); })() : this.value;
    }
    caseOf(patterns) {
        if ('_' in patterns) {
            return patterns._();
        }
        else {
            return this.isNothing() ? patterns.Nothing() : patterns.Just(this.value);
        }
    }
    orDefault(defaultValue) {
        return this.isNothing() ? defaultValue : this.value;
    }
    orDefaultLazy(getDefaultValue) {
        return this.isNothing() ? getDefaultValue() : this.value;
    }
    toList() {
        return this.isNothing() ? [] : [this.value];
    }
    mapOrDefault(f, defaultValue) {
        return this.map(f).orDefault(defaultValue);
    }
    extract() {
        return this.value;
    }
    toEither(left) {
        return this.isNothing() ? Either_1.Left(left) : Either_1.Right(this.value);
    }
    ifJust(effect) {
        return this.isJust() ? (effect(this.value), this) : this;
    }
    ifNothing(effect) {
        return this.isNothing() ? (effect(), this) : this;
    }
    filter(pred) {
        if (this.isNothing()) {
            return exports.Nothing;
        }
        return pred(this.value) ? exports.Just(this.value) : exports.Nothing;
    }
}
exports.Maybe = Maybe;
exports.Just = (value) => new Maybe(value);
exports.Nothing = new Maybe(null);
