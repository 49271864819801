import 'es6-promise/auto';
import 'cookieconsent';
import Stickyfill from 'stickyfilljs';
import objectFitImages from 'object-fit-images';

import {initGallery} from './gallery';

// Import styles
import '../css/main.scss';

// Configure position-sticky polyfill
const navMain = document.querySelector<HTMLElement>('#nav-main');
if (navMain !== null) {
    Stickyfill.add(navMain);
}

// Configure object-fit polyfill
objectFitImages();

import './mobile';
import './mobilenav';

// Cookie consent

window.addEventListener('load', () => {
    window.cookieconsent.initialise({
        animateRevokable: false,
        position: 'top',
        static: true,
        content: {
            message: 'Mit der Nutzung dieser Website erklären Sie sich mit dem Einsatz von Cookies einverstanden.',
            dismiss: 'Verstanden',
            link: 'Zur Datenschutzerklärung',
            href: 'http://freitach4u.de/datenschutz'
        }
    });
});

// Initialize gallery
if (document.querySelectorAll('#gallery').length > 0) {
    initGallery();
}