import {Maybe} from 'purify-ts/adts/Maybe';

import * as dom from './dom';

const $nav = Maybe.fromNullable(document.querySelector<HTMLElement>('nav .nav__mobile .dropdown'));
const $open = Maybe.fromNullable(document.querySelector<HTMLElement>('nav .nav__mobile .nav__button a'));
const $close = Maybe.fromNullable(document.querySelector<HTMLElement>('nav .nav__mobile .close'));

function measureScrollbar() {
    return Maybe.fromNullable(document.querySelector('body'))
        .map($body => {
            const scrollDiv = document.createElement('div');
            scrollDiv.className = 'scrollbar-measure';
            $body.append(scrollDiv);

            const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            $body.removeChild(scrollDiv);

            return scrollbarWidth;
        });
}

function enableBodyScroll() {
    Maybe.fromNullable(document.querySelector('html'))
        .map($html => {
            $html.style.overflow = '';
            $html.style.paddingRight = '';
        });
}

function disableBodyScroll() {
    Maybe.fromNullable(document.querySelector('html'))
        .map($html => {
            let windowWidth = window.innerWidth;

            if (!windowWidth && document.documentElement !== null) {
                const documentElementRect = document.documentElement.getBoundingClientRect();
                windowWidth = documentElementRect.right - Math.abs(documentElementRect.left);
            }

            const isOverflowing = document.body.clientWidth < windowWidth;
            const scrollbarWidth = measureScrollbar();

            $html.style.overflow = 'hidden';

            if (isOverflowing) {
                scrollbarWidth.map(w => $html.style.paddingRight = w + 'px')
            }
        })
}

function close() {
    enableBodyScroll();

    $nav.map($nav => dom.addClass($nav, 'hide'));
    $nav.map($nav => dom.removeClass($nav, 'nav__open'));
}

function open() {
    disableBodyScroll();

    $nav.map($nav => dom.removeClass($nav, 'hide'));
    $nav.map($nav => dom.addClass($nav, 'nav__open'));
}

$open.map($open => {
    $open.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        open();
    })
});

$close.map($close => {
    $close.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        close();
    })
});