import {debounce} from './utils';

// When loading responsive images, changing the screen orientation doesn't
// reload the images for the new screen size. Thus we need to force a re-render
// of all reponsive images so they fit the new screen size.
function resizeHandler() {
    let images = document.querySelectorAll<HTMLImageElement>('img');
    for (let image of images) {
        if (image.srcset === '') {
            continue;
        }

        image.srcset = image.srcset;
    }
}

window.addEventListener('resize', debounce(resizeHandler, 100));